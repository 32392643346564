import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles/index"
import { Container, Box, Typography, Grid } from "@material-ui/core/index"
import Button from "@material-ui/core/Button"
import { useMediaQuery } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: "transparent",
    padding: theme.spacing(22, 12, 30, 12),
    ["@media (max-width:780px)"]: {
      padding: theme.spacing(6, 4, 18, 6),
    },
  },
  container: {
    paddingLeft: "1rem",
    borderLeft: "4px solid #565A56",
  },
  titleBig: {
    color: "#fff",
    fontSize: "4rem",
    ["@media (max-width:780px)"]: {
      // paddingTop: 140,
      fontSize: "3rem",
      // color: "#fff",
      color: "#565A56",
    },
    ["@media (min-width:1440px)"]: {
      fontSize: "5rem",
      // color: "#565A56",
    },
  },
  titleSmall: {
    color: "#565A56",
    fontSize: "1.6rem",
    paddingTop: "0.6rem",
    letterSpacing: "1.5px",
    ["@media (max-width:1280px)"]: {
      color: "#AAB5A8",
      // color: "#565A56",
    },
    ["@media (max-width:780px)"]: {
      fontSize: "1rem",
      // color: "#0E0E0E",
      color: "#fff",
    },
  },
  bookNowButton: {
    marginTop: 30,
    color: "#fff!important",
    fontSize: "14px!important",
    backgroundColor: "#929B91",
    borderRadius: "40px!important",
    padding: "1.0em 4em!important",
    textDecoration: "none!important",
    "&:hover": {
      textDecoration: "none!important",
      opacity: "0.5!important",
      backgroundColor: "#929B91!important",
    },
    [theme.breakpoints.down("xs")]: {
      visibility: "hidden",
      marginTop: "134px!important",
      marginBottom: "24px!important",
      // padding: "0.8em 3em!important",
      padding: "0.8em 1em!important",
    },
  },
}))

const TheArtTitle = (leadBoxPopup) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Container maxWidth="xl" component="main" className={classes.heroContent}>
      {/* <Grid container className={classes.container}> */}
      <div className={classes.container}>
        <Typography
          component="h3"
          variant="h3"
          align="left"
          className={classes.titleBig}
        >
          <Box fontWeight="fontWeightLight">THE ART OF</Box>
          <Box fontWeight="fontWeightLight">AESTHETICS</Box>
        </Typography>
        <Typography
          variant="h5"
          align="left"
          component="h1"
          className={classes.titleSmall}
        >
          <Box>FACE INSTITUTE</Box>
        </Typography>
      </div>
      {/* Old leadpages booking button */}
      {/* <Button
        className={classes.bookNowButton}
        data-leadbox-popup="54wVxxHC73As8eStVVXLy8"
        data-leadbox-domain="kooldigital.lpages.co"
      >
        Book appointment
      </Button> */}
      {!smallScreen && (
        <Button
          href="https://schedule.clinicminds.com/?clinic=a8f5f0f3-1e41-11e9-881b-0a599d16f52a"
          className="clinicminds-scheduler-button"
          style={{
            backgroundColor: "#929B91",
            textDecoration: "none",
            fontSize: "14px",
            padding: "14px 50px",
            marginTop: "40px",
          }}
        >
          Book appointment
        </Button>
      )}
      {/* </Grid> */}
    </Container>
  )
}

export default TheArtTitle
